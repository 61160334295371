<template>
  <BasePageSection
    :bg-image="heroBgImg"
    class="home-hero"
    has-overlay
    overlay-color="#5e005b"
    full-bleed-bg
  >
    <VLayout row wrap justify-end py-4>
      <VFlex
        class="text-xs-left cta-wrap"
        :class="[`py-${$mq !== 'xs' ? '3' : '4'}`]"
        lg5
        sm6
        xs12
      >
        <VCard
          :class="[
            'hero-cta elevation-0 py-3 white',
            `px-${$mq !== 'xs' ? '4' : '3'}`,
          ]"
        >
          <h2 class="hero-title header-4">Be a Bub.</h2>

          <BasePageContent
            content-key="hero_text"
            default-content="Located in New Orleans, Bub’s NOLA is a purveyor of fine smash burgers, french fries &amp; so much more..."
            tag-class="hero-text"
            tag="div"
          />
          <BaseButtonOutline
            v-scroll-to="{ el: '#home-locations', offset: -100 }"
            class="ml-0"
            color="bubs-purple"
            :large="$mq !== 'xs'"
          >
            View {{ $mq !== 'xs' ? 'Our ' : '' }}Menus
          </BaseButtonOutline>
        </VCard>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BubsHomeHero',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    ...mapGetters('locations', ['getLocationBySlug']),
    ...mapGetters('pages', ['getPageContent']),
    heroPaddingAmount() {
      switch (this.$mq) {
        case 'xs':
          return '0'
        case 'sm':
          return '2'
        case 'md':
          return '3'
        default:
          return '5'
      }
    }, // heroPaddingAmount
    heroBgImg() {
      return (
        this.getPageContent('hero_bg_img') ||
        'https://res.cloudinary.com/resto/image/upload/v1661707214/resto/sites/5/bubs-facade.jpg'
      )
    },
  },
  methods: {},
}
</script>

<style lang="scss">
@import '@design';

.home-hero {
  position: relative;
  min-height: 40vh;
  @media only screen and (min-width: 960px) {
    > .row .container {
      max-width: 1440px;
      min-height: 50vh;
    }
  }
}

.cta-wrap {
  max-width: 400px !important;
}

.home-hero {
  .block-inner-container {
    display: flex;
    align-items: center;
  }
  .section-content {
    padding: 0;
  }
}
</style>
